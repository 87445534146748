import { h, Component }        from 'preact'
import { useState, useEffect } from 'preact/hooks'
import { Router }              from 'preact-router'

import Header   from 'components/header'
import Footer   from 'components/footer'
import Redirect from 'components/redirect'

import AccordPage    from 'routes/accord-page'
import CinemaPage    from 'routes/cinema-page'
import ClockPage     from 'routes/clock-page'
import DemagogPage   from 'routes/demagog-page'
import FaqPage       from 'routes/faq-page'
import FeedbackPage  from 'routes/feedback-page'
import GamePage      from 'routes/game-page'
import IgLinkPage    from 'routes/ig-link-page'
import IndexPage     from 'routes/index-page'
import LinksPage     from 'routes/links-page'
import ListPage      from 'routes/list-page'
import LoginPage     from 'routes/login-page'
import LogismPage    from 'routes/logism-page'
import NokiaPage     from 'routes/nokia-page'
import NotFoundPage  from 'routes/not-found-page'
import ObucheniePage from 'routes/obuchenie-page'
import PinarikPage   from 'routes/pinarik-page'
import ProjectPage   from 'routes/project-page'
import PronPage      from 'routes/pron-page'
import TestPage      from 'routes/test-page'
import ThingsPage    from 'routes/things-page'
import ToolPage      from 'routes/tool-page'
import TourismPage   from 'routes/tourism-page'
import YoutubePage   from 'routes/youtube-page'
import LibPage       from 'routes/lib-page'
import AdminPage     from 'routes/admin-page'
import ResumePage     from 'routes/resume-page'


import {AuthProvider} from 'context/auth'
import useAuth from 'hook/use-auth'

import './style/index.css'

const IndexApp = () => {
	const [isNotFound, setIsNotFound] = useState(false)
	const [auth, setAuth] = useState(false)
	const handleRoute = (event) => setIsNotFound(event.current.props.default || false)

	const { isAuth } = useAuth()

	useEffect(() => setAuth(isAuth), [isAuth])

	return (
		<div id="app">
		<AuthProvider isAuth={isAuth}>
			<Header isNotFound={isNotFound} />
			<Router onChange={handleRoute}>
				<IndexPage path="/" />
				<IgLinkPage path="/ig-link" />
				<TestPage path="/typo" />
				<ProjectPage path="/project/:vendor?/:project?" />
				<TourismPage path="/tourism/:first?/:second?/:third?" />
				<GamePage path="/game" />
				<LogismPage path="/logism" />
				<ToolPage path="/tool/:path?/:subpath?/:subsubpath?" />
				<DemagogPage path="/demagog" />
				<ClockPage path="/clock" />
				<AccordPage path="/accord/:idcode?" />
				<FaqPage path="/faq/:idcode?" />
				<FeedbackPage path="/feedback/:idcode?" />
				<ObucheniePage path="/obuchenie/:idcode?" />
				<ListPage path="/list-list/:first?/:second?/:third?" />
				<NokiaPage path="/nokia/:page?/:page?/:page?" />
				<ThingsPage path="/things/:page?" />
				<LoginPage path="/login" />
				<PinarikPage path="/pinarik" />
				<CinemaPage path="/cinema/:first?/:second?" />
				<PronPage path="/pron" />
				<YoutubePage path="/youtube" />
				<LinksPage path="/links" />
				<LibPage path="/lib/:first?/:second?/:third?" />
				<AdminPage path="/admin" />
				<ResumePage path="/resume" />

				<NotFoundPage default />
			</Router>
			<Footer isNotFound={isNotFound} />
		</AuthProvider>
		</div>
	)
}

export default IndexApp
